/** @jsx jsx */
import { jsx } from "theme-ui";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import SectionTitle from "../components/SectionTitle";
import ExternalLink from "../components/ExternalLink";
import PageContainer from "../components/PageContainer";

export const RetailersPageTemplate = ({ title, subheading, retailer }) => {
  retailer.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <PageContainer>
      <SectionTitle title={title}>{title}</SectionTitle>
      <p sx={{ textAlign: "center", maxWidth: "500px", mx: "auto", mt: 3 }}>
        {subheading}
      </p>
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          flexWrap: "wrap",
          my: [5, 6],
          maxWidth: "1200px",
          mx: "auto",
          pl: [0, "10%", "7%"],
        }}
      >
        {retailer?.map((retailer, index) => {
          return (
            <div
              key={index}
              sx={{
                width: ["100%", "calc(100% / 3)", "calc(100% / 4)"],
              }}
            >
              <h3 sx={{ fontSize: 3 }}>{retailer.name}</h3>
              <p>{retailer.address}</p>
              <p>{retailer.city}</p>
              <div sx={{ display: "block" }}>
                <ExternalLink
                  url={`https://${retailer.website}`}
                  color={"#333"}
                >
                  {retailer.website}
                </ExternalLink>
              </div>
              <div sx={{ display: "block" }}>
                <ExternalLink url={`mailto:${retailer.email}`} color={"#333"}>
                  {retailer.email}
                </ExternalLink>
              </div>
              <div sx={{ display: "block" }}>
                <ExternalLink url={`tel:${retailer.phone}`} color={"#333"}>
                  {retailer.phone}
                </ExternalLink>
              </div>
            </div>
          );
        })}
      </div>
    </PageContainer>
  );
};

RetailersPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
};

const RetailersPage = ({ data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout>
      <RetailersPageTemplate
        title={post.frontmatter.title}
        subheading={post.frontmatter.subheading}
        retailer={post.frontmatter.retailer}
      />
    </Layout>
  );
};

RetailersPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default RetailersPage;

export const retailersPageQuery = graphql`
  query RetailersPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "retailers-page" } }) {
      id
      frontmatter {
        title
        subheading
        retailer {
          name
          address
          city
          website
        }
      }
    }
  }
`;
